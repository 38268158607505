import { ReactNode } from 'react';
import useTranslation from 'next-translate/useTranslation';
import styled from '@emotion/styled';

import useGetPromoNowShowingFilms from '@app/hooks/fetch-data/useGetPromoNowShowingFilms';

import PageSection from '@app/components/layout/PageSection';
import RotatingFilmTileGroup from '@app/components/RotatingFilmTileGroup';

type PromoNowShowingSectionProps = {
  promoCampaign?: string;
  primaryColor: string;
  children: ReactNode;
};

const PromoNowShowingSection = ({
  promoCampaign,
  primaryColor,
  children,
}: PromoNowShowingSectionProps) => {
  const { t } = useTranslation('promo');
  const [films, isError] = useGetPromoNowShowingFilms();

  if (isError) {
    return null;
  }

  return (
    <Container primaryColor={primaryColor}>
      <PageSection>
        <ContentWrapper>
          <Content>
            <Title>{t('promo:promo.now_showing.title')}</Title>
            <Subtitle>{t('promo:promo.now_showing.subtitle')}</Subtitle>
            <RotatingFilmTileGroup
              films={films}
              snowplowElement="now_showing_film_tile"
              snowplowRelatedIds={
                promoCampaign ? { promo_campaign: promoCampaign } : null
              }
            >
              {rotatingFilmTile => (
                <FilmTileContainer>{rotatingFilmTile}</FilmTileContainer>
              )}
            </RotatingFilmTileGroup>
            <CtaContainer>{children}</CtaContainer>
          </Content>
        </ContentWrapper>
      </PageSection>
    </Container>
  );
};

type ContainerProps = {
  primaryColor: string;
};

const Container = styled.div<ContainerProps>`
  color: ${props => props.primaryColor};
`;

// extra wrapper required to fix ie11 flex bug
// https://github.com/philipwalton/flexbugs/blob/master/README.md#flexbug-3
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  min-height: 100vh;
  padding: 100px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h2`
  text-transform: uppercase;
  font-weight: 500;
  font-size: 32px;
  text-align: center;
`;

const Subtitle = styled.div`
  text-align: center;
  font-size: 26px;
  line-height: 1.2;
  margin: 12px 0 37px;
`;

const CtaContainer = styled.div`
  margin-top: 85px;

  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    margin-top: 60px;
  }
`;

const FilmTileContainer = styled.div`
  width: 100%;
  height: ${props => props.theme.grid.standardFilmTileHeight}px;

  &:not(:last-child) {
    margin-right: 10px;
  }
  @media (min-width: ${props => props.theme.mqNew.mobile}) {
    width: ${props => props.theme.grid.standardItemWidth}px;
  }
`;

export default PromoNowShowingSection;
