import { ReactNode } from 'react';
import styled from '@emotion/styled';

import ImageBase from '@app/components/ImageBase';
import PageSection from '@app/components/layout/PageSection';

type PromoHeadProps = {
  primaryColor: string;
  children: ReactNode;
  bodyText: ReactNode | string;
  titleText: ReactNode | string;
  logo?: string;
  isWide?: boolean;
};

const PromoHead = ({
  primaryColor,
  children,
  bodyText,
  titleText,
  logo = '',
  isWide = false,
}: PromoHeadProps) => (
  <Container primaryColor={primaryColor}>
    <PageSection>
      <ContentWrapper>
        <Content isWide={isWide}>
          {logo && <Logo src={`${logo}?size=x320`} alt="" loading="eager" />}
          <Title>{titleText}</Title>
          {bodyText && <Body primaryColor={primaryColor}>{bodyText}</Body>}
          {children}
        </Content>
      </ContentWrapper>
    </PageSection>
  </Container>
);

type Container = {
  primaryColor: string;
};

const Container = styled.div<Container>`
  color: ${props => props.primaryColor};
`;

// extra wrapper required to fix ie11 flex bug
// https://github.com/philipwalton/flexbugs/blob/master/README.md#flexbug-3
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

type ContentProps = {
  isWide: boolean;
};

const Content = styled.div<ContentProps>`
  min-height: 100vh;
  padding: 100px 0; /* prevent overlapping nav on short screens */

  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    width: ${props => (props.isWide ? 'auto' : '560px')};
  }
`;

const Title = styled.h1`
  font-size: 28px;
  line-height: 34px;
  font-weight: 500;
  text-transform: uppercase;
  max-width: 520px;
  margin-bottom: 12px;

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    font-size: 36px;
    line-height: 43px;
  }
`;

type BodyProps = {
  primaryColor: string;
};

const Body = styled.div<BodyProps>`
  font-size: 22px;
  line-height: 25px;
  margin-bottom: 25px;
  width: 100%; /* ie11 fix to stop it expanding to viewport width */

  a {
    color: ${props => props.primaryColor};
    text-decoration: underline;
  }

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    font-size: 28px;
    line-height: 32px;
  }
`;

const Logo = styled(ImageBase)`
  max-height: 140px;
  max-width: 300px;
  margin-bottom: 30px;

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    max-height: 160px;
    max-width: 350px;
  }
`;

export default PromoHead;
